<template>
	<div class="login" v-loading="loading">
		<div class="left">
			<img src="../assets/img/login/logins.png" alt="" class="logos">
			<div class="banners">
				<el-carousel>
					<el-carousel-item v-for="item in 4" :key="item" :loop="true">
						<div class="banners_img">
							<img src="../assets/img/login/bl_l.png" alt="">
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<div class="right">
			<div class="right_top">
				考勤综合管理系统
			</div>
			<div class="right_login">
				<div class="right_input">
					<img src="../assets/img/login/user.png" alt="">
					<input type="text" class="inpt_user" v-model="mobile" name="" id="" placeholder="账号">
				</div>
				<div class="right_input">
					<img src="../assets/img/login/passsed.png" alt="">
					<input type="password" class="inpt_password" v-model="password" name="" id="" placeholder="密码">
				</div>
			</div>
			<div class="btns">
				<button class="btns1" @click="login_submit()">登录</button>
			</div>
			<div class="banquan">
				<a href="https://beian.miit.gov.cn/" target="_blank">
					Copyright © 2024 嵩县金牛有限责任公司
				</a>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "login",
		components: {},
		data() {
			return {
				loading: false,
				loading_btn: false,
				btn_text: '',
				mobile: "",
				password: "",
				code: '',

				isSend: true, //可以发送
				timeFun: null,
				// 路由
				NavigateItem: [
				{
					title: '员工管理',
					type: 1,
					ac_show:false,
					image: require('@/assets/img/index/tab1.png'),
					list: [{
						title: '员工信息',
						path: '/Employee_Information',
						type: '1_1',
						ac_show:false,
					},
					{
						title: '通讯管理',
						path: '/Newsletter_management',
						type: '1_2',
						ac_show:false,
					},
					{
						title: '合同管理',
						path: '/Contract_management',
						type: '1_3',
						ac_show:false,
					},
					{
						title: '员工调动',
						path: '/Employee_transfers',
						type: '1_4',
						ac_show:false,
					},
					{
						title: '组织架构',
						path: '/Organizational_structure',
						type: '1_5',
						ac_show:false,
					},
					{
						title: '岗位列表',
						path: '/post_management',
						type: '1_6',
						ac_show:false,
					}

					]
				},
				{
					title: '薪酬管理',
					type: 2,
					ac_show:false,
					image: require('@/assets/img/index/tab2.png'),
					list: [
						{
							title: '社保管理',
							path: '/Social_security_management',
							type: '2_1',
							ac_show:false,
						},
						{
							title: '薪资设置',
							path: '/Salary_Management',
							type: '2_2',
							ac_show:false,
						},

						{
							title: '员工标准工资设置',
							path: '/Salary_setting',
							type: '2_3',
							ac_show:false,
						},
						{
							title: '工资下发',
							path: '/Salary_distribution',
							type: '2_4',
							ac_show:false,
						},

						{
							title: '薪资台账',
							path: '/Employee_salary',
							type: '2_5',
							ac_show:false,
						},
						// {
						// 	title: '结算月数据',
						// 	path: '/Billing_month_data',
						// }
					]
				},
				{
					title: '考勤管理',
					type: 3,
					ac_show:false,
					image: require('@/assets/img/index/tab3.png'),
					list: [{
						title: '考勤设置',
						path: '/Attendance_settings',
						type: '3_1',
						ac_show:false,
						list: [{
							title: '考勤规则',
							path: '/Attendance_settings/Attendance_rules',
							type: '3_1_1',
							ac_show:false,
						},
						{
							title: '免考勤人员设置',
							path: '/Attendance_settings/Attendance_no',
							type: '3_1_2',
							ac_show:false,
						},
						{
							title: '下井设置',
							path: '/Attendance_settings/Downhole_settings',
							type: '3_1_3',
							ac_show:false,
						},
						{
							title: '节假日设置',
							path: '/Attendance_settings/Holiday_settings',
							type: '3_1_4',
							ac_show:false,
						},
						]
					},
					{
						title: '考勤数据',
						path: '/Attendance_data',
						type: '3_2',
						ac_show:false,
						list: [{
							title: '每日考勤',
							path: '/Attendance_data/Daily_statistics',
							type: '3_2_1',
							ac_show:false,
						},
						{
							title: '月度考勤',
							path: '/Attendance_data/Monthly_rollups',
							type: '3_2_2',
							ac_show:false,
						}
						]
					}
						// {
						// 	title: '每日统计',
						// 	path: '/Daily_statistics',
						// },
						// {
						// 	title: '月度汇总',
						// 	path: '/Monthly_rollups',
						// },
						// {
						// 	title: '历史查询',
						// 	path: '/Historical_queries',
						// }
					]
				},
				{
					title: '员工福利',
					type: 4,
					ac_show:false,
					image: require('@/assets/img/index/tab4.png'),
					list: [
						{
							title: '礼品管理',
							path: '/Gift_management',
							type: '4_1',
							ac_show:false,
						},
						{
							title: '礼品下发',
							path: '/Gift_distributed',
							type: '4_2',
							ac_show:false,
						}
					]
				},
				{
					title: '劳保用品',
					type: 5,
					ac_show:false,
					image: require('@/assets/img/index/tab5.png'),
					list: [
						{
							title: '劳保用品管理',
							path: '/Labor_Management',
							type: '5_1',
							ac_show:false,
						},
						{
							title: '劳保用品下发',
							path: '/Labor_distributed',
							type: '5_2',
							ac_show:false,
						},
						// {
						// 	title: '领取劳保用品',
						// 	path: '/Labor_Receive',
						// 	type: '5_3',
						// 	ac_show:false,
						// }
					]
				},
				{
					title: '统计报表',
					type: 6,
					ac_show:false,
					image: require('@/assets/img/index/tab6.png'),
					list: [{
						title: '实时报表',
						path: '/Real_time_reports',
						type: '6_1',
						ac_show:false,
					},
					{
						title: '月报预览',
						path: '/monthly_report',
						type: '6_2',
						ac_show:false,
					},
					{
						title: '历史月报',
						path: '/Historical_Monthly',
						type: '6_3',
						ac_show:false,
					}
					]
				},
				{
					title: '账号管理',
					type: 7,
					ac_show:false,
					image: require('@/assets/img/index/tab7.png'),
					list: [{
						title: '账号管理',
						path: '/Account_management',
						type: '7_1',
						ac_show:false,
					},
					{
						title: '角色管理',
						path: '/Role_management',
						type: '7_2',
						ac_show:false,
					},
					{
						title: '系统设置',
						path: '/system_setting',
						type: '7_3',
						ac_show:false,
					},
					{
						title: '操作日志',
						path: '/History_recording',
						type: '7_4',
						ac_show:false,
					}
					]
				},
			],
			};
		},
		computed: {},
		watch: {


		},
		created() {
			// localStorage.setItem('token','')
			localStorage.clear()
			this.$store.dispatch('get_type_setting')

		},
		beforeDestroy() {
			clearInterval(this.timeFun);
		},
		methods: {
			login_submit() {

				if (!this.mobile.trim()) {
					alertErr('请输入账号');
					return;
				}


				if (!this.password.trim()) {
					alertErr('请输入密码');
					return;
				}
				this.loading = true
				this.$api("admin_login", {
					username: this.mobile.trim(),
					password: this.password.trim(),
				}, "post").then((res) => {
					console.log("登录", res);
					if (res.code == 200) {
						alertSucc('登录成功')
						// this.loading = false
						localStorage.setItem('token', res.data.token)
						localStorage.setItem('logo_data', JSON.stringify(res.data))
						this.get_user()
						

						// this.$store.dispatch('query_user')
						// this.$router.push('/index')
					} else {
						this.loading = false
						alertErr(res.msg)
					}

				});
			},
			//获取用户信息
			get_user(){
				// this.loading = true
				this.$api("getAdminInfo", {
					 
				}, "get").then((res) => {
					console.log("获取用户信息", res);
					if (res.code == 200) {
						this.loading = false
						localStorage.setItem('cache_user', JSON.stringify(res.data))
						this.get_login_qx(res.data)

						// this.$store.dispatch('query_user')
						// this.$router.push('/index')
					} else {
						this.loading = false
						alertErr(res.msg)
					}

				});
			},
			//判断用户权限
			get_login_qx(data){
				this.$api("getAdminRole", {
					id:data.role_id
				}, "get").then((res) => {
					this.loading = false
					console.log('菜单列表',res)
					if (res.code == 200) {
						let qx_list=JSON.parse(res.data.roles)
						console.log('权限列表',qx_list)
						for (let i = 0; i < qx_list.length; i++) {
							let e = qx_list[i];
							for (let j = 0; j < this.NavigateItem.length; j++) {
								let v = this.NavigateItem[j];
								// v.list
								for (let n = 0; n < v.list.length; n++) {
									let v2 = v.list[n];
									// console.log('v2',v2)
									if(e==v2.type){
										this.$router.push({
											path:v2.path
										})	
										console.log('v2',v2)
										return
									}
								}
								
							}
						}
						this.NavigateItem.forEach((e)=>{
							qx_list.forEach((v)=>{
								if(e.type==v){
									this.$router.push({
										path:e.list[0].path
									})							
								}
							})
						})
						// if(qx_list.find(v => v == 2)){
						// 	this.$router.push('/Employee_Information')
						// }else if(qx_list.find(v => v == 2)){
						// 	this.$router.push('/Social_security_management')

						// }else if(qx_list.find(v => v == 3)){
						// 	this.$router.push('/Employee_Information')

						// }else if(qx_list.find(v => v == 4)){
						// 	this.$router.push('/Attendance_settings/Attendance_rules')

						// }else if(qx_list.find(v => v == 5)){
						// 	this.$router.push('/Gift_management')

						// }else if(qx_list.find(v => v == 6)){
						// 	this.$router.push('/Labor_Management')

						// }else if(qx_list.find(v => v == 7)){
						// 	this.$router.push('/Real_time_reports')

						// }else if(qx_list.find(v => v == 8)){
						// 	this.$router.push('/Account_management')

						// }

					} else {
						alertErr(res.msg)
					}
				});
			},


		},
	};
</script>
<style scoped lang="less">
	.login {
		width: 100vw;
		height: 100vh;
		display: flex;
		justify-content: space-between;

		.left {
			width: 50%;
			// height: 1080px;
			height: 100%;
			// background: url('../assets/img/login/bl_l.png');//
			// position: fixed;
			text-align: center;
			position: relative;

			// padding-top: 243px;
			.logos {
				width: 362px;
				height: 71px;
				position: absolute;
				left: 36px;
				top: 36px;
				z-index: 2;
			}

			.banners {
				position: relative;
				z-index: 1;
				width: 100%;
				height: 100%;

				// background-color: red;
				/deep/.el-carousel {
					height: 100%;

					.el-carousel__container {
						height: 100%;

						.banners_img {
							width: 100%;
							height: 100%;

						}
					}

					.el-carousel__indicators {
						.el-carousel__indicator {
							.el-carousel__button {

								width: 14px;
								height: 14px;
								background: #FFFFFF;
								border-radius: 100px 100px 100px 100px;
								opacity: 0.5;
							}

						}

						.is-active {
							.el-carousel__button {

								width: 48px;
								height: 14px;
								background: #FFFFFF;
								border-radius: 100px 100px 100px 100px;
								opacity: 1;

							}
						}
					}
				}


			}
		}

		.right {
			width: 50%;
			height: 100vh;
			position: relative;

			// height: 1080px;
			// background: url('../assets/img/login/bg_r1.png');
			.right_top {
				text-align: center;
				padding-top: 217px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 36px;
				color: #333333;
			}

			.right_login {
				padding-top: 110px;
				width: 424px;
				margin: auto;

				.right_input {
					width: 424px;
					height: 50px;
					background: #F7F8FA;
					border-radius: 4px 4px 4px 4px;
					opacity: 1;
					display: flex;
					align-items: center;
					padding-left: 16px;
					padding-right: 16px;
					margin: auto;
					margin-bottom: 22px;

					.inpt_user {
						padding-left: 20px;
					}

					.inpt_password {
						padding-left: 20px;
					}

					input {
						width: 100%;
					}
				}

				.right_input_code {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 424px;
					height: 50px;
					margin-bottom: 22px;

					.inputs {
						width: 293px;
						height: 50px;
						background: #F7F8FA;
						border-radius: 4px 4px 4px 4px;
						display: flex;
						align-items: center;
						padding-left: 16px;
						padding-right: 16px;

						.inpt_user {
							padding-left: 20px;
						}

						.inpt_password {
							padding-left: 20px;
						}

						input {
							width: 100%;
						}
					}

					.code_btns {
						width: 121px;
						height: 50px;
						background: #273A8E;
						border-radius: 4px 4px 4px 4px;
						line-height: 50px;
						text-align: center;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;
					}
				}

			}

			.forgot_password {
				width: 424px;
				margin: auto;
				// padding-top: 23px;
				display: flex;
				align-items: center;
				justify-content: end;

				.text {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #686868;
					cursor: pointer;
				}
			}

			.btns {
				margin-top: 60px;
				text-align: center;

				.btns1 {
					width: 424px;
					height: 50px;
					background: #2373C8;
					border-radius: 27px 27px 27px 27px;
					text-align: center;
					line-height: 50px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;

				}
			}

			.banquan {
				position: absolute;
				bottom: 40px;
				width: 100%;
				text-align: center;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #7E7E7E;

				a {
					text-align: center;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #7E7E7E;
				}
			}

		}
	}
</style>